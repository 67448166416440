export enum PRMovements {
  HBSQ = "High Bar Squat",
  LBSQ = "Low Bar Squat",
  BP = "Bench Press",
  DL = "Deadlift",
  SDL = "Sumo Deadlift",
  OP = "Overhead Press",
}

export enum PRMovementsRaw {
  HBSQR = "High Bar Squat Raw",
  LBSQR = "Low Bar Squat Raw",
  DLR = "Deadlift Raw",
  SDLR = "Sumo Deadlift Raw",
  BPR = "Bench Press Pause",
}

export enum PRFilterMovements {
  BP = "Bench Press",
  DL = "Deadlift",
  SDL = "Sumo Deadlift",
  ADL = "All Deadlift",
  HBSQ = "High Bar Squat",
  LBSQ = "Low Bar Squat",
  ASQ = "All Squat",
  OP = "Overhead Press",
}

export enum PowerliftingAssociations {
  AEP = "Asociación Española de Powerlifting",
  WRPF = "Federación Mundial de Powerlifting Raw",
}

export enum PRAccessories {
  belt = "Cinturón",
  elbowPads = "Coderas",
  kneePads = "Rodilleras",
  kneeBands = "Vendas",
  straps = "Straps",
  wristBands = "Muñequeras",
}

export enum GenderTypes {
  M = "Masculino",
  F = "Femenino",
}

export const WeightCategories = [
  "-45",
  "-50",
  "-55",
  "-60",
  "-65",
  "-70",
  "-75",
  "-80",
  "-85",
  "+85",
  "-90",
  "+90",
  "-95",
  "-100",
  "-105",
  "-110",
  "-120",
  "+120",
  "-125",
  "-140",
  "+140",
];

export const AEPWeightCategories = [
  "-43",
  "-47",
  "-52",
  "-53",
  "-57",
  "-59",
  "-63",
  "-66",
  "-69",
  "-72",
  "-74",
  "-76",
  "-83",
  "-84",
  "+84",
  "-93",
  "-105",
  "-120",
  "+120",
];

export const AEPMaleWeightCategories = [
  "-53",
  "-59",
  "-66",
  "-74",
  "-83",
  "-93",
  "-105",
  "-120",
  "+120",
];

export const AEPFemaleWeightCategories = [
  "-43",
  "-47",
  "-52",
  "-57",
  "-63",
  "-69",
  "-72",
  "-76",
  "-84",
  "+84",
];

export const WRPFWeightCategories = [
  "44",
  "48",
  "52",
  "56",
  "60",
  "67.5",
  "75",
  "82.5",
  "90",
  "+90",
  "100",
  "110",
  "125",
  "140",
  "+140",
];

export const WRPFMaleWeightCategories = [
  "52",
  "56",
  "60",
  "67.5",
  "75",
  "82.5",
  "90",
  "100",
  "110",
  "125",
  "140",
  "+140",
];

export const WRPFFemaleWeightCategories = [
  "44",
  "48",
  "52",
  "56",
  "60",
  "67.5",
  "75",
  "82.5",
  "90",
  "+90",
];

export const AgesCategories = [
  { name: "Subjunior", age: "14-18" },
  { name: "Junior", age: "18-23" },
  { name: "Senior", age: "23-40" },
  { name: "Máster I", age: "40" },
  { name: "Máster II", age: "50" },
  { name: "Máster III", age: "60" },
  { name: "Máster IV", age: "70" },
];

export const MinRecordWeigth = 1;
export const MaxRecordWeigth = 550;
export const MinSeries = 1;
export const MaxSeries = 50;
export const MinRepetitions = 1;
export const MaxRepetitions = 100;

export const KgToLb = 2.2046;
