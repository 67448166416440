import { Conditions } from "../../shared/constants/conditions.constants";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-conditions",
  templateUrl: "./conditions.component.html",
  styleUrls: ["./conditions.component.scss"],
})
export class ConditionsComponent {
  title = "Términos y condiciones";
  conditions = Conditions;
}
