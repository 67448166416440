import { Router } from "@angular/router";
import { Component, OnInit, Input } from "@angular/core";
import { Location } from "@angular/common";

@Component({
  selector: "app-generic-info-page",
  templateUrl: "./generic-info-page.component.html",
  styleUrls: ["./generic-info-page.component.scss"],
})
export class GenericInfoPageComponent {
  @Input() title;
  @Input() list;

  constructor(public location: Location) {}

  goBack() {
    this.location.back();
  }
}
