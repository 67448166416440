import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { ConditionsComponent } from "./pages/conditions/conditions.component";
import { PrivacyComponent } from "./pages/privacy/privacy.component";
import { LoginPage } from "./pages/login/login.page";

const routes: Routes = [
  {
    path: "home",
    component: HomeComponent,
  },
  {
    path: "conditions",
    component: ConditionsComponent,
  },
  {
    path: "privacy",
    component: PrivacyComponent,
  },
  {
    path: "login",
    component: LoginPage,
    // loadChildren: () =>
    //   import("./login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "tabs",
    loadChildren: () =>
      import("./pages/tabs/tabs.module").then((m) => m.TabsPageModule),
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
