import { Component } from "@angular/core";

import {
  Platform,
  ToastController,
  MenuController,
  AlertController,
} from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AuthService } from "./core/firebase/authentication-service";
import { Router } from "@angular/router";
import { UserService } from "./core/firebase/user-service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public authService: AuthService,
    public router: Router,
    public toastController: ToastController,
    private menu: MenuController,
    public alertController: AlertController,
    private userService: UserService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    if (!this.authService.isLoggedIn()) {
      this.router.navigate(["home"]);
    } else {
      this.router.navigate(["tabs"]);
    }
  }

  isLoggoutButtonVisible() {
    return this.router?.url.includes("profile");
  }

  redirectToHome() {
    this.router.navigate(["home"]);
  }

  redirectToLogin() {
    this.router.navigate(["login"]);
  }

  redirectToConditions() {
    this.toggleMenu();
    this.router.navigate(["conditions"]);
  }

  redirectToPrivacy() {
    this.toggleMenu();
    this.router.navigate(["privacy"]);
  }

  logout() {
    this.authService.logout();
  }

  changePassword() {
    const email = this.authService.getUser().email;
    this.authService
      .passwordRecover(email)
      .then(() => {
        this.presentToast(
          true,
          "Se ha enviado el mail para modificar la contraseña"
        );
      })
      .catch((error) => this.presentToast(false, error));
  }

  deleteAccount(password: string) {
    const email = this.authService.getUser().email;
    this.authService
      .login(email, password)
      .then(() => {
        this.authService
          .deleteUser()
          .then(() => {
            this.userService
              .deleteUser(this.authService.getUserId())
              .then(() => {
                this.presentToast(true, "Cuenta eliminada");
                this.logout();
              })
              .catch((error) => this.presentToast(false, error));
          })
          .catch((error) => this.presentToast(false, error));
      })
      .catch((error) => this.presentToast(false, error));
  }

  toggleMenu() {
    this.menu.toggle();
  }

  closeMenu() {
    this.menu.close();
  }

  async openLogoutAlert() {
    this.toggleMenu();
    const alert = await this.alertController.create({
      header: "¿Cerrar sesión?",
      message: "Esperamos tenerle de vuelta muy pronto.",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
        },
        {
          text: "Aceptar",
          handler: () => {
            this.logout();
          },
        },
      ],
    });

    await alert.present();
  }

  async openChangePasswordAlert() {
    this.toggleMenu();
    const alert = await this.alertController.create({
      header: "Cambiar contraseña",
      message:
        "Se enviará un link de restablecimiento de contraseña a su correo electrónico.",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
        },
        {
          text: "Enviar mail",
          handler: () => {
            this.changePassword();
          },
        },
      ],
    });

    await alert.present();
  }

  async openDeleteAccountAlert() {
    this.closeMenu();
    const alert = await this.alertController.create({
      header: "¿Está seguro?",
      message: "Vuelve a introducir su contraseña para continuar:",
      inputs: [
        {
          name: "password",
          type: "password",
          placeholder: "Contraseña",
        },
      ],
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
        },
        {
          text: "Eliminar cuenta",
          handler: (alertData) => {
            if (alertData.password.length < 6) {
              this.presentToast(false, "Introduce una contraseña válida");
              this.openDeleteAccountAlert();
            } else {
              this.deleteAccount(alertData.password);
            }
          },
        },
      ],
    });

    await alert.present();
  }

  async presentToast(isSuccess: boolean, message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: "top",
      animated: true,
      color: isSuccess ? "success" : "danger",
    });
    toast.present();
  }
}
