import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { Observable, Subscription } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  user: Observable<any>;
  subscriptions = new Subscription();

  constructor(private firebaseAuth: AngularFireAuth, public router: Router) {
    this.user = firebaseAuth.authState;
    this.user.subscribe((user) => {
      if (user) {
        if (user.emailVerified) {
          this.initUser(user);
        }
      } else {
        localStorage.setItem("user", null);
        this.subscriptions.unsubscribe();
      }
    });
  }

  initUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
    this.subscriptions = new Subscription();
  }

  isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    return user && user.emailVerified;
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  getUserId() {
    return JSON.parse(localStorage.getItem("user"))?.uid;
  }

  signup(email: string, password: string) {
    return this.firebaseAuth.createUserWithEmailAndPassword(email, password);
  }

  login(email: string, password: string) {
    return this.firebaseAuth.signInWithEmailAndPassword(email, password);
  }

  async sendVerificationMail() {
    return (await this.firebaseAuth.currentUser).sendEmailVerification();
  }

  async deleteUser() {
    return (await this.firebaseAuth.currentUser).delete();
  }

  passwordRecover(passwordResetEmail: string) {
    return this.firebaseAuth.sendPasswordResetEmail(passwordResetEmail);
  }

  isEmailVerified(): boolean {
    const user = this.getUser();
    return user && user.emailVerified !== false ? true : false;
  }

  isUserExisting(email: string) {
    return this.firebaseAuth.fetchSignInMethodsForEmail(email);
  }

  logout() {
    this.firebaseAuth.signOut().then(() => {
      localStorage.setItem("user", null);
      this.router.navigate(["login"]);
    });
  }
}
