import { GenderTypes } from "./../constants/records.enum";
import { Record } from "./record.model";
export class User {
  id: string;
  admin: boolean;
  name: string;
  email: string;
  birthDate: string;
  height: number;
  weight: number;
  gender: GenderTypes;
  province: string;
  town: string;
  gym: string;
  profileImg: string | ArrayBuffer;
  profileImgName: string;
  records: Record[];
  insta: string;

  constructor(
    id: string,
    name: string,
    email: string,
    gender: GenderTypes,
    birthDate: string
  ) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.gender = gender;
    this.birthDate = birthDate;
  }
}
