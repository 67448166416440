import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { firebaseConfig } from "../environments/firebaseconfig";
import { FormsModule } from "@angular/forms";
import { HomeComponent } from "./pages/home/home.component";
import { ConditionsComponent } from "./pages/conditions/conditions.component";
import { PrivacyComponent } from "./pages/privacy/privacy.component";
import { GenericInfoPageComponent } from "./shared/components/generic-info-page/generic-info-page.component";
import { LoginPage } from "./pages/login/login.page";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ConditionsComponent,
    PrivacyComponent,
    GenericInfoPageComponent,
    LoginPage,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    FormsModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
