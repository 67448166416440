import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { getActualYear } from "../../shared/helper/date.helper";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  actualYear = getActualYear();
  textList = [
    {
      id: "ranking-info",
      title1: "Explora el ranking",
      title2: "¡y participa!",
      description:
        "Explora el mayor ranking de fuerza no oficial para saber quien levanta más peso tanto en SBD (Sentadilla + Press Banca + Peso Muerto) como en un movimiento en particular. Filtra con las opciones que más te interesen para saber quien es el más fuerte!",
      img: "../../assets/images/ranking.jpeg",
    },
    {
      id: "records-info",
      title1: "Visualiza tus records",
      title2: "¡con vídeo incluído!",
      description:
        "Visualiza todos tus records y el resumen de tus mejores marcas.",
      img: "../../assets/images/best-records.jpeg",
    },
    {
      id: "new-record-info",
      title1: "Guarda tus records",
      title2: "¡y supérate día a día!",
      description:
        "Cada vez que hagas un nuevo record súbelo a PR Legacy para competir con los demás y subir posiciones en el ranking.",
      img: "../../assets/images/new-record.jpeg",
    },
    {
      id: "progress-info",
      title1: "Observa tu progreso",
      title2: "mediante gráficas",
      description:
        "Mediante las gráficas podrás ver tu evolución desde los inicios y motivarte para superarte a ti mismo.",
      img: "../../assets/images/progress.jpeg",
    },
  ];
  stickyIndex = 0;
  constructor(public router: Router) {}

  ngOnInit() {}

  redirectToLogin() {
    this.router.navigate(["login"]);
  }

  redirectToConditions() {
    this.router.navigate(["conditions"]);
  }

  redirectToPrivacy() {
    this.router.navigate(["privacy"]);
  }

  onScroll(event: any) {
    const rankingElement = document.getElementById(this.textList[0].id);
    const recordsElement = document.getElementById(this.textList[1].id);
    const newRecordElement = document.getElementById(this.textList[2].id);
    const progressElement = document.getElementById(this.textList[3].id);
    const offsetHeight = rankingElement.offsetHeight / 4;

    const rankingVideo = <HTMLVideoElement>(
      document.getElementById("videoRanking")
    );
    const recordsVideo = <HTMLVideoElement>(
      document.getElementById("videoRecords")
    );
    const newRecordVideo = <HTMLVideoElement>(
      document.getElementById("videoNewRecord")
    );
    const progressVideo = <HTMLVideoElement>(
      document.getElementById("videoProgress")
    );

    const scroll = event.detail.scrollTop;

    if (
      scroll >= rankingElement.offsetTop &&
      scroll < rankingElement.offsetTop + offsetHeight
    ) {
      this.stickyIndex = 0;
      if (rankingVideo.paused) {
        const playPromise = rankingVideo.play();

        if (playPromise !== undefined) {
          playPromise.catch((error) => {});
        }
      }
    } else if (
      scroll >= recordsElement.offsetTop &&
      scroll < recordsElement.offsetTop + offsetHeight
    ) {
      this.stickyIndex = 1;
      if (recordsVideo.paused) {
        const playPromise = recordsVideo.play();

        if (playPromise !== undefined) {
          playPromise.catch((error) => {});
        }
      }
    } else if (
      scroll >= newRecordElement.offsetTop &&
      scroll < newRecordElement.offsetTop + offsetHeight
    ) {
      this.stickyIndex = 2;
      if (newRecordVideo.paused) {
        const playPromise = newRecordVideo.play();

        if (playPromise !== undefined) {
          playPromise.catch((error) => {});
        }
      }
    } else if (scroll >= progressElement.offsetTop) {
      this.stickyIndex = 3;
      if (progressVideo.paused) {
        const playPromise = progressVideo.play();

        if (playPromise !== undefined) {
          playPromise.catch((error) => {});
        }
      }
    }
  }
}
