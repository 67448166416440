import { GenderTypes } from "../../shared/constants/records.enum";
import { UserService } from "../../core/firebase/user-service";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../core/firebase/authentication-service";
import { Router } from "@angular/router";
import { ToastController, AlertController } from "@ionic/angular";
import { getActualDate } from "../../shared/helper/date.helper";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-login",
  templateUrl: "./login.page.html",
  styleUrls: ["./login.page.scss"],
})
export class LoginPage implements OnInit {
  isLoginView: boolean = true;
  isLoginLoading: boolean = false;
  name: string;
  email: string;
  password: string;
  gender: GenderTypes;
  genderTypes = GenderTypes;
  genderTypesList = Object.keys(GenderTypes);
  birthDate: string;
  actualDate = getActualDate();
  acceptConditions = false;
  isResendVerificationMail = false;
  isSignUpButtonClicked = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    public toastController: ToastController,
    public alertController: AlertController
  ) {}

  ngOnInit(): void {
    this.isLoggedIn();
  }

  ionViewWillEnter() {
    this.isLoggedIn();
  }

  isLoggedIn() {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(["tabs"]);
    }
  }

  segmentChanged(ev: any) {
    this.isLoginView = ev.detail.value === "login";
  }

  signup() {
    this.isSignUpButtonClicked = true;
    this.birthDate = this.birthDate.slice(0, 10);
    this.name = this.name.split(" ").map(this.capitalize).join(" ");
    this.isLoginLoading = true;
    this.isResendVerificationMail = false;
    this.authService
      .signup(this.email, this.password)
      .then((user) => {
        this.isLoginLoading = true;
        this.userService
          .createUser(user, this.name, this.gender, this.birthDate)
          .then(() => {
            this.isLoginLoading = true;
            this.authService
              .sendVerificationMail()
              .then(() => {
                this.presentToast(
                  true,
                  "Cuenta creada, se ha enviado el mail de verificación"
                );
                this.loginFinished();
              })
              .catch((error) => this.catchError(error));
          })
          .catch((error) => this.catchError(error));
      })
      .catch((error) => this.catchError(error.message));
  }

  capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  login() {
    this.isSignUpButtonClicked = false;
    this.isLoginLoading = true;
    this.isResendVerificationMail = false;
    this.authService
      .login(this.email, this.password)
      .then(() => {
        this.authService.user
          .pipe(filter((user) => !this.isSignUpButtonClicked && user))
          .subscribe((user) => {
            if (user.emailVerified) {
              this.loginFinished();
              if (!this.authService.getUser()) {
                this.authService.initUser(user);
              }
              this.router.navigate(["tabs"]);
            } else {
              this.isLoginLoading = false;
              this.isResendVerificationMail = true;
              this.presentToast(
                false,
                "Es necesaria la verificación de la cuenta"
              );
            }
          });
      })
      .catch((error) => this.catchError(error.message));
  }

  loginFinished() {
    this.isLoginLoading = false;
    this.isLoginView = true;
    this.gender = null;
    this.password = this.birthDate = this.name = "";
  }

  catchError(error: string) {
    this.isLoginLoading = false;
    this.presentToast(false, error);
  }

  logout() {
    this.authService.logout();
  }

  resendVerificationMail() {
    this.authService
      .sendVerificationMail()
      .then(() => {
        this.presentToast(true, "Se ha enviado el mail de verificación");
        this.isResendVerificationMail = false;
      })
      .catch((error) => this.presentToast(false, error));
  }

  changePassword(email: string) {
    this.authService
      .passwordRecover(email)
      .then(() => {
        this.presentToast(
          true,
          "Se ha enviado el mail para modificar la contraseña"
        );
      })
      .catch((error) => this.presentToast(false, error));
  }

  async openForgotPasswordAlert() {
    const alert = await this.alertController.create({
      header: "Restablecer contraseña",
      message:
        "Se enviará un link de restablecimiento de contraseña a su correo electrónico.",
      inputs: [
        {
          name: "mail",
          type: "email",
          placeholder: "example@example.com",
        },
      ],
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
        },
        {
          text: "Restablecer",
          handler: (alertData) => {
            this.authService
              .isUserExisting(alertData.mail)
              .then((signInMethods) => {
                if (signInMethods.length) {
                  this.changePassword(alertData.mail);
                } else {
                  this.presentToast(
                    false,
                    "El correo introducido no existe en la base de datos"
                  );
                }
              })
              .catch((error) => this.presentToast(false, error));
          },
        },
      ],
    });

    await alert.present();
  }

  async presentToast(isSuccess: boolean, message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: "top",
      animated: true,
      color: isSuccess ? "success" : "danger",
    });
    toast.present();
  }
}
