export const Conditions = [
  {
    paragraphs: [
      "Aquí se explican nuestros términos de uso. Cuando usted usa PR Legacy, está aceptando todas las normas que se especifican en esta página.",
      "Al usar nuestra web estará aceptando cumplir estas normas jurídicamente vinculantes (los “Términos”). Asimismo, también acepta adherirse a nuestra Política de privacidad y cumplir todas las normas de PR Legacy.",
      "Es posible que de vez en cuando modifiquemos estos términos. Si lo hacemos, le avisaremos de los cambios significativos mediante un aviso en PR Legacy o a través de un correo electrónico. Las nuevas versiones de los términos nunca se aplicarán de forma retroactiva y le informaremos de la fecha exacta en la que entrarán en vigor. Si sigue usando PR Legacy después de una modificación, se considerará que acepta los nuevos términos.",
    ],
    title: "Bienvenidos a PR Legacy",
  },
  {
    paragraphs: [
      "No existe límite de edad para registrarse en PR Legacy. Se puede acceder a la página principal de PR Legacy sin necesidad de tener una cuenta creada, pero para la utilización de sus funciones como guardar records, observar tu progreso, ver y/o aparecer en el ranking, es necesario tener una cuenta.",
      "Usted es responsable de toda la actividad de su cuenta y de mantener la confidencialidad de su contraseña. Si descubre que otra persona ha usado su cuenta sin su permiso deberá denunciarlo mediante un correo electrónico a contacto@prlegacy.es.",
    ],
    title: "Creación de una cuenta",
  },
  {
    paragraphs: [
      "No trate de interferir en el correcto funcionamiento de los Servicios. No sortee las medidas que hemos puesto en práctica para proteger los Servicios. No trate de dañar ni conseguir un acceso no autorizado a ningún sistema, datos, contraseñas ni ninguna otra información, tanto si pertenecen a PR Legacy como si son propiedad de un tercero. No realice ninguna acción que imponga una carga no razonable a nuestra infraestructura o a nuestros proveedores. (Nos reservamos el derecho a determinar qué es razonable). No use ningún tipo de software ni dispositivo (ni manual ni automático) para “rastrear” cualquier parte de PR Legacy. No desmonte ni realice ingeniería inversa en ningún aspecto de PR Legacy para tratar de acceder al código fuente, las ideas fundamentales o los algoritmos, entre otros elementos.",
    ],
    title: "Prohibiciones",
  },
  {
    paragraphs: [
      "El contenido presente en PR Legacy está protegido de varias maneras. Usted tiene derecho a usarlo con ciertos fines personales, pero no lo puede usar con fines comerciales sin obtener permiso previo.",
      "Los servicios de PR Legacy están protegidos jurídicamente de varias maneras, como mediante derechos de propiedad intelectual, marcas, patentes y secretos comerciales, así como otros derechos y leyes. Usted acepta respetar todas las menciones, datos y restricciones legales sobre derechos de propiedad intelectual incluidos en cualquier contenido al que se acceda a través del Sitio. También acepta no modificar, traducir ni crear de ninguna otra manera obras derivadas del Servicio.",
      "PR Legacy le concede una licencia para reproducir contenido de los Servicios únicamente para uso personal. Esta licencia cubre tanto el contenido protegido propio de PR Legacy como el contenido generado por los usuarios del Sitio. Esta licencia es mundial, no exclusiva, no sublicenciable e intransferible. Si usted desea usar, reproducir, modificar, distribuir o almacenar este contenido con fines comerciales, necesita obtener la autorización previa por escrito de PR Legacy o del titular del derecho de propiedad intelectual correspondiente. 'Fines comerciales' significa que usted tiene la intención de usar, vender, ceder, alquilar o explotar de cualquier otra forma, contenido para su uso comercial mediante cualquier método.",
    ],
    title: "Propiedad intelectual e industrial de PR Legacy",
  },
  {
    paragraphs: [
      "Puede eliminar su cuenta en cualquier momento. Todos los datos personales de su cuenta personal serán eliminados automáticamente.",
      "Puede cerrar su cuenta en cualquier momento, pero todas las cláusulas del presente acuerdo seguirán teniendo vigencia tras la cancelación de la cuenta, incluidos nuestros derechos relativos a cualquier contenido que usted ya haya enviado a PR Legacy.",
    ],
    title: "Eliminación de su cuenta",
  },
  {
    paragraphs: [
      "Para poder funcionar, necesitamos mantener el control sobre todo lo que ocurre en PR Legacy. Por eso, en esta sección nos reservamos el derecho a tomar decisiones para proteger la solidez y la integridad de nuestro sistema. No nos tomamos estas competencias a la ligera, y solo las usaremos cuando no tengamos otra alternativa. PR Legacy se reserva los derechos siguientes:",
      "Podemos realizar cambios en PR Legacy y los Servicios de PR Legacy sin avisar y sin incurrir en ningún tipo de responsabilidad.",
      "Tenemos derecho a decidir quién puede utilizar PR Legacy. Podemos cancelar cuentas o rehusar ofrecer nuestros Servicios, especialmente si se está haciendo mal uso de ellos. Podemos modificar nuestros criterios de aceptación en cualquier momento. Si estas prácticas están prohibidas por ley en su lugar de residencia, revocaremos su derecho a usar PR Legacy en su jurisdicción.",
      "Tenemos derecho a invalidar cualquier record de fuerza subido por el usuario, en cualquier momento y por cualquier motivo.",
      "PR Legacy no es responsable de daños derivados de ninguna de estas acciones, y nuestra política es no realizar comentarios sobre los motivos de dichas acciones.",
    ],
    title: "Nuestros derechos",
  },
  {
    paragraphs: [
      "El presente Contrato tiene carácter mercantil y deberá ser interpretado y cumplido según sus propios términos y, en lo no previsto, se regirá por la legislación española.",
      "En la medida permitida por la legislación aplicable, para la resolución de cualquier controversia derivada de la validez, interpretación, cumplimiento o ejecución de este Contrato, las partes, con renuncia expresa a cualquier otro fuero que pudiera corresponderles, expresamente se someten a la jurisdicción y competencia de los Juzgados y Tribunales de la ciudad de Barcelona.",
    ],
    title: "Legislación y jurisdicción aplicable",
  },
];

export const Privacy = [
  {
    paragraphs: [
      "PR Legacy se compromete a respetar la privacidad de quienes visitan y utilizan la web.",
      "La presente Política de privacidad contiene la forma de procesamiento de sus datos personales, las categorías de datos personales implicadas en estas actividades de procesamiento, así como su objetivo y sus bases legales. También explicamos cuándo y por qué compartimos sus datos personales con terceros.",
      "En la presente Política de privacidad, también le informamos de sus derechos relacionados con el procesamiento que llevamos a cabo de sus datos personales, así como sobre cualquier transferencia que realicemos de los mismos.",
      "La utilización de la web o de cualquiera de sus servicios asociados implica la aceptación por el usuario de las disposiciones contenidas en la presente Política de Privacidad, y que sus datos personales sean tratados según se estipula en la misma.",
    ],
    title: "Ámbito de aplicación",
  },
  {
    paragraphs: [
      "Los datos personales del usuario recogidos a través del formulario de registro de la web se almacenan en un fichero protegido alojado en los servidores de Firebase (Google) cuyo acceso único puede ser efectuado por Eric Rodríguez Balsells, con domicilio fiscal en C/ Benviure 40, Sant Boi de Llobregat (Barcelona).",
    ],
    title: "Responsable del fichero",
  },
  {
    paragraphs: [
      "Los datos personales que el usuario facilita al rellenar el formulario de registro y la información de su perfil una vez registrado (nombre, apellidos, correo electrónico y otros datos personales) serán tratados por PR Legacy para poder registrar los records del usuario y poder filtrarlos tanto en el ranking general como en sus records privados.",
    ],
    title: "Finalidades",
  },
  {
    paragraphs: [
      "PR Legacy tratará los datos personales de los usuarios con total confidencialidad y seguridad utilizando proveedores externos de confianza y canales de comunicación seguros.",
      "PR Legacy informa que adoptará las medidas de índole técnica y organizativas necesarias para evitar la alteración, pérdida, tratamiento o acceso no autorizado de los datos, habida cuenta del estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que están expuestos, ya provengan de la acción humana o del medio físico o natural.",
    ],
    title: "Confidencialidad y seguridad",
  },
  {
    paragraphs: [
      "PR Legacy no vende ni cede a otras empresas los datos personales de sus usuarios para fines lucrativos.",
    ],
    title: "Cesión de datos personales",
  },
  {
    paragraphs: [
      "En el caso de que los datos aportados por un usuario pertenecieran a un tercero distinto de quien facilita los datos, el usuario deberá informar al tercero de los aspectos contenidos en esta Política de Privacidad y, al facilitarlos, el usuario reconoce y manifiesta haber obtenido la autorización de dicho tercero para facilitar sus datos a PR Legacy. Si PR Legacy lo requiere, el usuario deberá acreditar haber obtenido dicho consentimiento.",
    ],
    title: "Datos de terceros",
  },
  {
    paragraphs: [
      "En el supuesto de que un usuario proporcione datos de menores de catorce (14) años, este garantiza haber obtenido el previo consentimiento de sus padres o tutores. Si PR Legacy lo requiere, el usuario deberá acreditar haber obtenido dicho consentimiento.",
    ],
    title: "Menores",
  },
  {
    paragraphs: [
      "Los usuarios garantizan que los datos personales aportados son verdaderos, exactos, completos y actualizados. Los usuarios serán los únicos responsables de cualquier daño o perjuicio, directo o indirecto, que pudiera ocasionarse como consecuencia del incumplimiento de tal obligación.",
      "Asimismo, a los efectos de garantizar el deber de calidad y la actualización de los datos, el usuario deberá comunicar a PR Legacy cualquier modificación que pudiera tener lugar en los datos facilitados.",
    ],
    title: "Calidad de los datos",
  },
  {
    paragraphs: ["En nuestra web no se almacenan Cookies."],
    title: "Política de Cookies",
  },
  {
    paragraphs: [
      "El usuario tiene reconocidos los derechos de acceso, rectificación, cancelación y oposición (A.R.C.O) respecto al tratamiento de sus datos personales.",
      "El usuario dispone de una opción en la web que permite eliminar la cuenta de manera definitiva. Esa opción elimina completamente todo su perfil de usuario almacenado en nuestras bases de datos.",
    ],
    title: "Derechos de acceso, rectificación, cancelación y oposición",
  },
];
