import { Component, OnInit } from "@angular/core";
import { Privacy } from "src/app/shared/constants/conditions.constants";

@Component({
  selector: "app-privacy",
  templateUrl: "./privacy.component.html",
  styleUrls: ["./privacy.component.scss"],
})
export class PrivacyComponent {
  title = "Política de Privacidad";
  privacy = Privacy;
}
