import { GenderTypes } from "../../shared/constants/records.enum";
import { Injectable } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from "@angular/fire/database";
import { User } from "../../shared/models/user.model";

@Injectable({
  providedIn: "root",
})
export class UserService {
  usersListRef: AngularFireList<any>;
  userRef: AngularFireObject<any>;
  userModel: User;
  isUserLoading = false;

  constructor(private db: AngularFireDatabase) {
    this.usersListRef = this.db.list("/users");
  }

  // Create
  createUser({ user }, name: string, gender: GenderTypes, birthDate: string) {
    return this.db.database
      .ref("/users/" + user.uid)
      .update(new User(user.uid, name, user.email, gender, birthDate));
  }

  // Get Single
  getUser(id: string) {
    this.userRef = this.db.object("/users/" + id);
    return this.userRef;
  }

  getUserByEmail() {
    return this.db.list("/users", (ref) =>
      ref.orderByChild("email").equalTo("erick29496@gmail.com")
    );
  }

  // Get User List
  getUsersList() {
    return this.usersListRef;
  }

  // Update
  updateUser(id: string, user: User) {
    this.userRef = this.db.object("/users/" + id);
    return this.userRef.update(user);
  }

  // Delete
  deleteUser(id: string) {
    this.userRef = this.db.object("/users/" + id);
    return this.userRef.remove();
  }

  setUserModel(user: User) {
    this.userModel = user;
  }

  getUserModel() {
    return this.userModel;
  }

  getIsUserLoading() {
    return this.isUserLoading;
  }
}
