export function getActualDate(): string {
  const today = new Date();
  return new Date(today.getTime() - today.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, 10);
}

export function getActualYear() {
  return new Date().getFullYear();
}

export function getFormattedDate(date: string) {
  return new Date(date).toLocaleDateString();
}

export function getAge(birthday: string) {
  const ageDifMs = Date.now() - new Date(birthday).getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function getAgeInSpecificDate(birthday: string, date: string) {
  const ageDifMs = new Date(date).getTime() - new Date(birthday).getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function getYearText(year: number) {
  return year === new Date().getFullYear() ? "Último año" : year;
}
